import { useTranslate } from "@lobby/ocb-intl";
import { useMemo } from "react";

import { ProfileSection } from "@widget/profile-modal/lib";
import { Info } from "@widget/profile-modal/ui/common/profile-sections/info";

import { BetHistory } from "./bet-history";
import { Bonuses } from "./bonuses";
import { GameHistory } from "./game-history";
import { Settings } from "./settings";
import { Wallet } from "./wallet";

import { Player } from "@entities/player";
import type { TProfileSection } from "@widget/profile-modal/lib";

export type TProfileSectionItem = {
  id: TProfileSection;
  title: string;
  icon: string;
  component: React.ReactNode;
};

export const useProfileSections = (): TProfileSectionItem[] => {
  const { $t } = useTranslate();
  const { data } = Player.usePlayer();

  const isWalletEnabled = Boolean(data?.isDepositActive) || Boolean(data?.isWithdrawActive);

  return useMemo(() => {
    const sections = [
      {
        id: ProfileSection.Bonuses,
        title: $t({ defaultMessage: "Bonuses" }),
        icon: "order",
        component: <Bonuses />,
      },
      {
        id: ProfileSection.GameHistory,
        title: $t({ defaultMessage: "Game history" }),
        icon: "gamepad",
        component: <GameHistory />,
      },
      {
        id: ProfileSection.SportBetsHistory,
        title: $t({ defaultMessage: "Sport Bets history" }),
        icon: "trophySolid",
        component: <BetHistory />,
      },
      isWalletEnabled && {
        id: ProfileSection.Wallet,
        title: $t({ defaultMessage: "Wallet" }),
        icon: "wallet",
        component: <Wallet />,
      },
      {
        id: ProfileSection.Info,
        title: $t({ defaultMessage: "Info" }),
        icon: "info",
        component: <Info />,
      },
      {
        id: ProfileSection.Settings,
        title: $t({ defaultMessage: "Settings" }),
        icon: "settings",
        component: <Settings />,
      },
    ];

    return sections.filter(Boolean) as TProfileSectionItem[];
  }, [isWalletEnabled, $t]);
};

import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import { Player, usePlayerMoneyFormatter } from "@entities/player";
import { useModal } from "@shared/lib";
import { Modal } from "@shared/ui/modal";
import { SVGIcon } from "@shared/ui/svg-icon";

export function TakeBonusModal() {
  const { isOpen, close } = useModal("take-bonus");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <TakeBonusComponent />
    </Modal>
  );
}

function TakeBonusComponent() {
  const { $t } = useTranslate();
  const navigate = useNavigate();
  const { close: closeModal } = useModal("take-bonus");
  const formatMoney = usePlayerMoneyFormatter();

  const { data } = Player.useBalances();
  const bonus = data?.bonus as {
    amount: number;
    type: string;
  };
  const activateBonusMutation = Player.useActivateBonus();

  const amount = formatMoney(bonus?.amount);

  function handleActivateBonus() {
    activateBonusMutation.mutate();
    closeModal();
  }

  useEffect(() => {
    if (!bonus) {
      navigate({
        search: (prev) => ({ ...prev, modal: undefined }),
        replace: true,
      });
    }
  }, [bonus, activateBonusMutation, navigate]);

  return (
    <Modal.Panel className="relative top-10 w-fit max-w-full rounded-t-[23.75rem] border-1 border-yellow-orange bg-yellow-orange bg-opacity-30 p-2.5 lg:min-w-[18.75rem]">
      <div className="rounded-inherit bg-gradient-to-t from-[#ffb23f] to-burnt-sienna px-8 pb-5 lg:px-7.5 lg:pt-12.5 lg:pb-10">
        <div className="-top-8 -translate-x-1/2 absolute left-1/2 w-44 drop-shadow-[0.5rem_0.5rem_0_rgba(0,0,0,0.25)] lg:h-72 lg:w-60">
          <SVGIcon name="bonusStamp" />
        </div>
        <div className="flex flex-col items-center pt-44 lg:pt-52">
          <div className="font-commissioner font-extrabold text-4xl text-white uppercase italic tracking-wider [text-shadow:0.25rem_0.25rem_0_rgba(0,0,0,0.3)]">
            {$t({ defaultMessage: "Bonus" })}
            {skipIntl("!")}
          </div>
          <div className="mt-5 font-bold text-2xl text-white uppercase">{bonus?.type}</div>
          <div className="mt-2.5 font-commissioner font-extrabold text-4xl text-white uppercase italic tracking-wider [text-shadow:0.25rem_0.25rem_0_rgba(0,0,0,0.3)]">
            {amount}
          </div>
          <div className="mt-5 grid mobile-only:grid-rows-2 gap-5 lg:grid-cols-2">
            <button
              className="h-9 whitespace-nowrap rounded-rounded bg-white px-7.5 font-bold text-ebony-clay text-sm uppercase [box-shadow:0.5rem_0.5rem_0_rgba(0,0,0,0.25)] hover:text-keppel active:translate-x-[0.5rem] active:translate-y-[0.5rem] active:[box-shadow:none]"
              type="button"
              onClick={closeModal}>
              {$t({ defaultMessage: "later" })}
            </button>
            <button
              className="h-9 whitespace-nowrap rounded-rounded bg-java px-7.5 font-bold text-ebony-clay text-sm uppercase [box-shadow:0.5rem_0.5rem_0_rgba(0,0,0,0.25)] hover:text-white active:translate-x-[0.5rem] active:translate-y-[0.5rem] active:[box-shadow:none]"
              type="button"
              onClick={handleActivateBonus}>
              {$t({ defaultMessage: "take" })}
            </button>
          </div>
        </div>
      </div>
    </Modal.Panel>
  );
}

import { profileSections } from "@widget/profile-modal";

import { isLobbyDemoMode } from "./helpers";

import type { TProfileSection} from "@widget/profile-modal";

export function validateSearchModal(search: Record<string, unknown>) {
  if (search.modal === "log-in") {
    return isLobbyDemoMode() ? "log-in" : undefined;
  }
  return search.modal as string;
}

export function validateSearchProfileSection(
  search: Record<string, unknown>,
): TProfileSection | undefined {
  const profileSection = search.profileSection as TProfileSection;
  if (profileSection && search.modal === "profile" && profileSections.includes(profileSection)) {
    return profileSection;
  }
}

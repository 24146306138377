import { Skeleton } from "@shared/ui";

import type { PropsWithChildren } from "react";

export function Separator({ children }: PropsWithChildren) {
  return (
    <div className="my-5 flex items-center">
      <div className="flex-grow border-shark border-t" />
      <span className="mx-3 flex-shrink">{children}</span>
      <div className="flex-grow border-shark border-t" />
    </div>
  );
}

export function AuthEndpointsContainer({ children }: PropsWithChildren) {
  return (
    <div className="grid grid-cols-[repeat(auto-fit,8.125rem)] justify-center gap-3 *:h-10">
      {children}
    </div>
  );
}

export function AuthEndpointsSkeleton() {
  return (
    <>
      <Skeleton className="my-5 h-6 bg-silver" />
      <AuthEndpointsContainer>
        <Skeleton className="bg-silver" />
        <Skeleton className="bg-silver" />
        <Skeleton className="bg-silver" />
      </AuthEndpointsContainer>
    </>
  );
}

import { FortuneWheel as FortuneWheelContext } from "@entities/fortune-wheel";
import { usePlayerMoneyFormatter } from "@entities/player";
import { CongratsModal } from "@features/congrats-modal";
import { TakeBonusButton } from "@features/take-bonus-button";
import { useTranslate } from "@lobby/ocb-intl";
import { emitter, useMobile } from "@shared/lib";
import { Button, FloatGroup } from "@shared/ui";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Footer } from "@widget/footer";
import { FortuneWheel } from "@widget/fortune-wheel";
import type { TFortuneWheelSectorParams } from "@widget/fortune-wheel";
import { type TSector, WHEEL_SECTORS_REPEAT_COUNT } from "@widget/fortune-wheel/lib";
import { Header } from "@widget/header";
import { SupportChat } from "@widget/support-chat";
import { useEffect, useState } from "react";
import { BackgroundDecorators } from "./background-decorators";
import { RulesSectionDesktop } from "./rules-section.desktop";
import { RulesSectionMobile } from "./rules-section.mobile";

export function FortuneWheelPage() {
  const [wheelSectors, setWheelSectors] = useState<TSector[]>([]);

  const { $t } = useTranslate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const formatMoney = usePlayerMoneyFormatter();

  const { data, refetch } = FortuneWheelContext.useWheelSettings();

  const settings = data?.result;
  const rules = settings?.rules;

  function invalidateWheelState() {
    queryClient.invalidateQueries({ queryKey: ["Lobby.getCurrent"] });
    queryClient.invalidateQueries({ queryKey: ["FortuneWheel.getSettings"] });
  }

  function handleWinAnimationEnd(params: TFortuneWheelSectorParams) {
    emitter.emit("CONGRATS_MODAL_OPEN", {
      message: $t({ defaultMessage: "you won" }),
      value: formatMoney(params.value),
      colors: params.colors,
      onTake: () => {
        invalidateWheelState();
        navigate({ to: "/casino", replace: true });
      },
    });
  }

  function handleDepositAbsent() {
    emitter.emit(
      "WARNING_MODAL_OPEN",
      $t({ defaultMessage: "Make a deposit before spinning the wheel of fortune" }),
    );
  }

  function repeatSectors(sectors: TSector[], count: number) {
    const repeatedSectors = [...sectors];
    for (let i = 0; i < sectors.length * count; i++) {
      const sector = sectors[i % sectors.length];
      repeatedSectors.push({ ...sector, id: i * -1 });
    }

    return repeatedSectors;
  }

  useEffect(() => () => void refetch(), []);

  useEffect(() => {
    if (!settings) return;

    const sectors = settings.sectors;
    if (sectors) {
      setWheelSectors(repeatSectors(sectors, WHEEL_SECTORS_REPEAT_COUNT));
    }

    if (settings.state === "make_deposit") {
      handleDepositAbsent();
    }
  }, [settings]);

  return (
    <div className="from-mercury to-keppel bg-gradient-to-b flex flex-col min-h-dvh dark:lg:from-outer-space dark:lg:to-[#255d5a] dark:from-outer-space dark:from-15% dark:via-[#255d5a] dark:to-outer-space">
      <Header />
      <main className="flex-grow pb-4 overflow-hidden relative">
        <div className="lg:max-w-screen-3xl lg:px-5 lg:mt-5 px-3 mx-auto mt-3 mobile-only:absolute">
          <Button type="primary" onClick={() => navigate({ to: "/casino" })}>
            {$t({ defaultMessage: "Back" })}
          </Button>
        </div>

        <div className="lg:flex-center lg:gap-15 lg:mt-24 lg:px-5 mt-12 mb-5 px-3 relative">
          {!isMobile && <BackgroundDecorators />}

          <div className="flex-center isolate w-full relative mobile-only:mt-2">
            {!isMobile && <RulesSectionDesktop rules={rules} />}

            <FortuneWheel
              className="lg:w-[50.625rem] mobile-only:max-w-96 w-full"
              sectors={wheelSectors}
              onWinAnimationEnd={handleWinAnimationEnd}
            />
          </div>
        </div>

        {isMobile && (
          <div className="px-3 isolate w-full">
            <RulesSectionMobile rules={rules} />
          </div>
        )}
      </main>
      <Footer className="mt-3" />

      <CongratsModal />

      <FloatGroup>
        <SupportChat className="mobile-only:z-[1]" />
        <TakeBonusButton className="lg:mt-10 mt-5" />
      </FloatGroup>
    </div>
  );
}

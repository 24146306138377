import curacaoGamingLogo from "@assets/curacao-gaming-logo.png";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import { clsx } from "clsx";

import { useMobile } from "@shared/lib";
import { Logo, SVGIcon } from "@shared/ui";

function FooterMainSection() {
  const { $t } = useTranslate();
  return (
    <div className="lg:flex-row lg:gap-5 flex-col flex-center gap-3">
      <div className="flex gap-2 lg:contents">
        <img src={curacaoGamingLogo} alt="Curacao Gaming" className="lg:h-11 h-5" />
        <div className="flex items-center">
          <div className="lg:text-lg lg:h-8 lg:w-11 bg-bright-gray rounded-rounded flex-center text-2xs text-white font-extrabold mr-1.5 w-6 h-5 dark:bg-white dark:text-outer-space">
            {$t({ defaultMessage: "18+" })}
          </div>
          <div className="lg:text-sm lg:leading-4 text-3xs uppercase italic font-bold">
            <span>{$t({ defaultMessage: "game" })}</span>
            <br />
            <span>{$t({ defaultMessage: "responsibly" })}</span>
          </div>
        </div>
      </div>
      <p className="lg:text-base mobile-only:-order-1 text-2xs mobile-only:text-center">
        {$t(
          {
            defaultMessage:
              "Gambling can be addictive. Play responsibly. {themeName} only accept customers over 18 years of age.",
          },
          {
            themeName: "RICHBET",
          },
        )}
      </p>
    </div>
  );
}

export function Footer({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const isMobile = useMobile();

  return (
    <footer className={clsx(className, "bg-athens-gray dark:bg-outer-space")}>
      <div className="lg:max-w-screen-3xl mx-auto lg:p-5 p-3">
        <div className="lg:contents flex flex-col gap-2">
          <div className="lg:gap-5 flex-c-sb gap-3">
            <Link className="shrink-0" to="/">
              <Logo className="lg:h-11 h-5" />
            </Link>

            {!isMobile && <FooterMainSection />}

            <div
              className="lg:text-base lg:gap-2.5 gap-1 flex-center text-2xs text-keppel font-medium cursor-pointer whitespace-nowrap dark:text-java"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <span>{$t({ defaultMessage: "Back to top" })}</span>
              <button
                className="lg:size-8 lg:hover:text-outer-space lg:dark:hover:text-white bg-mercury rounded-rounded flex-center size-5 dark:bg-ebony-clay"
                type="button"
              >
                <SVGIcon className="lg:size-3 size-1.5 -rotate-90" name="arrowRight" />
              </button>
            </div>
          </div>

          {isMobile && <FooterMainSection />}
        </div>
      </div>
    </footer>
  );
}

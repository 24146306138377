import { createRouter } from "@tanstack/react-router";

import { routeTree } from "@app/routeTree.gen";
import { queryClient } from "@lobby/core/app";

export const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: false,
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

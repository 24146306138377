import { createNanoEvents } from "nanoevents";

import type { TLocale } from "@shared/lib/locales";
import type { ICongratsModalProps } from "./types";

interface Events {
  ERROR_MODAL_OPEN: (error: Error) => void;
  WARNING_MODAL_OPEN: (message: string) => void;
  SUCCESS_MODAL_OPEN: (message: string) => void;
  CONGRATS_MODAL_OPEN: (params: ICongratsModalProps) => void;
  SUPPORT_CHAT_OPEN: () => void;
  PLAYER_LOGIN: () => void;
  PLAYER_LOGOUT: () => void;
  SET_LOCALE: (locale: TLocale) => void;
  THEME_CHANGED: (theme: "dark" | "light") => void;
  PLAYER_BALANCE_VISIBILITY_CHANGED: (isVisible: boolean) => void;
}

export const emitter = createNanoEvents<Events>();

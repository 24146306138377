import { FavouriteBtn } from "@entities/game/ui/favourite-btn";
import { MobileMenuButton } from "@features/mobile-menu-button";
import { Game, type TGame } from "@lobby/core/entities/game";
import { SVGIcon } from "@shared/ui";
import { Link } from "@tanstack/react-router";
import { useState } from "react";

interface IGamePageControlsMobileProps {
  game: TGame | undefined;
}

export function GamePageControlsMobile({ game }: IGamePageControlsMobileProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const favouriteMutation = Game.useFavouriteSetter();

  const isFavourite = Boolean(game?.isFavourite);
  const gameId = Number(game?.id);

  return (
    <div
      className={`bg-outer-space bg-opacity-50 fixed top-14 right-0 z-[100] transition-transform ${isExpanded ? "translate-x-0" : "translate-x-1"}`}
    >
      <div className="flex items-center">
        <div
          className="backdrop-blur-sm bg-outer-space bg-opacity-50 border-r-1 border-athens-gray border-opacity-50 flex-center w-8 fixed top-0 left-0 h-full -translate-x-1"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <SVGIcon
            className={`text-keppel dark:text-java size-4 ${isExpanded ? "rotate-0" : "rotate-180"}`}
            name="arrowRight"
          />
        </div>
        <div className="backdrop-blur-sm flex items-center h-full px-1.5 *:flex-center *:p-3">
          <div>
            <Link to="/casino" search>
              <SVGIcon className="size-5 text-keppel dark:text-java" name="close" />
            </Link>
          </div>

          <div>
            <FavouriteBtn
              className="*:[&&]:size-5"
              isFavourite={isFavourite}
              onClick={() => favouriteMutation.mutate({ gameId, isFavourite: !isFavourite })}
            />
          </div>

          <div>
            <MobileMenuButton />
          </div>
        </div>
      </div>
    </div>
  );
}

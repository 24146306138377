import { Lobby } from "@lobby/core/entities/lobby";
import { Skeleton } from "@shared/ui/skeleton";
import { useMobileBanners } from "../lib/use-banner";
import { BannersListMobile } from "./banners-list.mobile";

export function BannerPanelMobile() {
  const { isPending } = Lobby.useBanner();
  const banners = useMobileBanners();

  const isBannerEmpty = !banners || banners.length === 0;

  return (
    (isPending || !isBannerEmpty) && (
      <div className="group [aspect-ratio:2.206]">
        {isPending && <Skeleton className="h-full bg-silver" />}
        {!isBannerEmpty && <BannersListMobile banners={banners} />}
      </div>
    )
  );
}

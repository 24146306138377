import { Game, type TGames } from "@lobby/core/entities/game";
import { Lobby } from "@lobby/core/entities/lobby";
import { useNavigate } from "@tanstack/react-router";
import { useMemo } from "react";
import type { TBanner } from "./types";

type TAction = { type: string; value: string } | undefined;

function getButtonParams(
  action: TAction,
  navigate: ReturnType<typeof useNavigate>,
  gameList: TGames,
) {
  if (!action) return null;

  const textKey = getButtonTextKey(action.type);
  if (!textKey) return null;

  return {
    textKey: textKey as "playNow" | "open",
    action: getButtonAction(action, navigate, gameList),
  };
}

function getButtonTextKey(action: string) {
  switch (String(action).toLowerCase()) {
    case "provider":
    case "game":
      return "playNow";
    case "url":
      return "open";
    default:
      return null;
  }
}

function getButtonAction(
  action: TAction,
  navigate: ReturnType<typeof useNavigate>,
  gameList: TGames,
) {
  if (!action) return () => null;

  switch (String(action.type).toLowerCase()) {
    case "provider":
      return () =>
        navigate({
          replace: true,
          search: (prev) => ({
            ...prev,
            pid: action.value,
          }),
        });
    case "game": {
      const game = gameList?.find((game) => game.id === Number(action.value));
      if (!game) return () => null;

      return () =>
        navigate({
          to: "/game/$gameId",
          params: {
            gameId: game.stringId,
          },
        });
    }
    case "url":
      return () => window.open(action.value, "_blank");
    default:
      return () => null;
  }
}

export function useMobileBanners() {
  const navigate = useNavigate();

  const { data } = Lobby.useBanner();
  const { data: gameList } = Game.useList();

  const banners = data?.banners;

  return useMemo(
    () =>
      banners?.reduce((acc, banner, idx) => {
        if (banner.image.mobile) {
          acc.push({
            ...banner,
            id: idx,
            image: banner.image.mobile,
            button: getButtonParams(banner.action as TAction, navigate, gameList as TGames),
          });
        }
        return acc;
      }, [] as TBanner[]),
    [banners, gameList, navigate],
  );
}

export function useDesktopBanners() {
  const navigate = useNavigate();

  const { data } = Lobby.useBanner();
  const { data: gameList } = Game.useList();

  const banners = data?.banners;

  return useMemo(
    () =>
      banners?.reduce((acc, banner, idx) => {
        if (banner.image.desktop) {
          acc.push({
            ...banner,
            id: idx,
            image: banner.image.desktop,
            button: getButtonParams(banner.action as TAction, navigate, gameList as TGames),
          });
        }
        return acc;
      }, [] as TBanner[]),
    [banners, gameList, navigate],
  );
}

import {
  Checkbox as HeadlessCheckbox,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { Skeleton } from "@shared/ui";
import { clsx } from "clsx";
import type { PropsWithChildren } from "react";

interface ICheckboxProps {
  name: string;
  invalid?: boolean;
  onChange?: (checked: boolean) => void;
}

export function Checkbox({ name, invalid, children, onChange }: PropsWithChildren<ICheckboxProps>) {
  return (
    <div className="flex items-baseline gap-2">
      <HeadlessCheckbox
        className={`group block size-5 flex-center cursor-pointer rounded-rounded border bg-athens-gray data-[checked]:border-shark data-[checked]:bg-keppel data-[checked]:ring-0 dark:data-[checked]:bg-java ${invalid ? "border-radical-red ring-1 ring-radical-red" : "border-shark"}`}
        name={name}
        defaultChecked={false}
        onChange={onChange}>
        <div className="leading-none opacity-0 group-data-[checked]:opacity-100 dark:text-bright-gray">
          &#10004;
        </div>
      </HeadlessCheckbox>
      <Label className="flex-1 text-shark text-xs dark:text-mercury">{children}</Label>
    </div>
  );
}

export function Separator({ children }: PropsWithChildren) {
  return (
    <div className="my-5 flex items-center">
      <div className="flex-grow border-shark border-t" />
      <span className="mx-3 flex-shrink">{children}</span>
      <div className="flex-grow border-shark border-t" />
    </div>
  );
}

export function AuthEndpointsContainer({ children }: PropsWithChildren) {
  return (
    <div className="grid grid-cols-[repeat(auto-fit,8.125rem)] justify-center gap-3 *:h-10">
      {children}
    </div>
  );
}

export function AuthEndpointsSkeleton() {
  return (
    <>
      <Skeleton className="my-5 h-6 bg-silver" />
      <AuthEndpointsContainer>
        <Skeleton className="bg-silver" />
        <Skeleton className="bg-silver" />
        <Skeleton className="bg-silver" />
      </AuthEndpointsContainer>
    </>
  );
}

export function CurrencySelector({ currencies }: { currencies: string[] }) {
  return (
    <Listbox name="currency" defaultValue={currencies[0]}>
      <ListboxButton
        className={clsx(
          "flex items-center gap-2 rounded-rounded border-1 border-shark/80 bg-white/5 px-3 py-1.5 font-bold",
          "data-[focus]:-outline-offset-2 focus:outline-none data-[focus]:outline-2 data-[focus]:outline-white/25",
        )}>
        {({ value }) => (
          <>
            {value}
            <svg width="7" height="7" fill="none" viewBox="0 0 7 7">
              <path
                className="fill-outer-space dark:fill-white"
                d="M4.366 6a1 1 0 0 1-1.732 0L.469 2.25a1 1 0 0 1 .866-1.5h4.33a1 1 0 0 1 .866 1.5L4.366 6Z"
              />
            </svg>
          </>
        )}
      </ListboxButton>
      <ListboxOptions
        anchor={{
          to: "bottom start",
          gap: "0.25rem",
        }}
        transition
        className="z-[1000] origin-bottom rounded-rounded border-1 border-shark/80 bg-mercury p-2 font-bold transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 lg:p-1.5 dark:bg-bright-gray">
        {currencies.map((currency) => (
          <ListboxOption
            key={currency}
            value={currency}
            className="cursor-default rounded-rounded px-3 py-1 lg:px-3 lg:py-1.5 lg:data-[focus]:bg-keppel lg:data-[focus]:text-white lg:dark:data-[focus]:bg-java lg:dark:data-[focus]:text-black">
            {currency}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}

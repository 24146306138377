import { useNavigate, useSearch } from "@tanstack/react-router";
import { useCallback, useMemo } from "react";

export function useModal(id: string) {
  const isOpen = useSearch({ strict: false, select: (search) => search.modal === id });
  const navigate = useNavigate();

  const open = useCallback(() => {
    navigate({
      search: (prev) => ({
        ...prev,
        modal: id,
      }),
    });
  }, [id, navigate]);

  const close = useCallback(() => {
    navigate({
      search: (prev) => ({
        ...prev,
        modal: undefined,
        profileSection: undefined,
      }),
    });
  }, [navigate]);

  return useMemo(() => ({ isOpen, open, close }), [isOpen, open, close]);
}

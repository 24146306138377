import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useState } from "react";

import { emitter } from "@shared/lib";
import { Button, Modal } from "@shared/ui";

export function SuccessModal() {
  const { $t } = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const unbind = emitter.on("SUCCESS_MODAL_OPEN", (message) => {
      setIsOpened(true);
      setMessage(message);
    });

    return unbind;
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />

      <Modal.Panel
        className="w-max max-w-[90%] rounded-rounded border-1 border-keppel bg-keppel/30 p-2 lg:min-w-96 lg:max-w-[25rem] lg:p-2.5 dark:border-java dark:bg-java/30"
        centered>
        <div className="rounded-rounded bg-athens-gray dark:bg-outer-space">
          <div className="px-5 py-5 lg:px-7.5 lg:py-3">
            <div>
              <div>
                <div className="text-center font-bold text-keppel text-sm lg:text-xl dark:text-java">
                  {$t({ defaultMessage: "success" }).toUpperCase()}
                </div>
                <div className="mt-2 text-sm lg:text-lg">
                  <div className="whitespace-break-spaces">{message}</div>
                </div>
              </div>
            </div>
            <Button className="mx-auto mt-6" onClick={() => setIsOpened(false)}>
              {$t({ defaultMessage: "ok" }).toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}

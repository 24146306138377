// GENERATED FILE (DO NOT MODIFIED THIS!!!)
export const localesMap = {
  "de": { code: "de", name: "Deutsch" },
  "el": { code: "el", name: "Ελληνικά" },
  "en": { code: "en", name: "English" },
  "es": { code: "es", name: "Español" },
  "hr": { code: "hr", name: "Hrvatski" },
  "pt": { code: "pt", name: "Português" },
  "sq": { code: "sq", name: "Shqiptare" },
  // "sr": { code: "sr", name: "Српски" },
  "sr-en": { code: "sr-en", name: "Serbian" },
  "tr": { code: "tr", name: "Türk" },
  "zh": { code: "zh", name: "中文" },
};
export type TLocale = keyof typeof localesMap;

import { ProviderButton } from "@features/provider-button";
import { Game } from "@lobby/core/entities/game";
import { useTranslate } from "@lobby/ocb-intl";
import { debounce, useModal } from "@shared/lib";
import { InputSearch } from "@shared/ui/input-search";
import { Modal } from "@shared/ui/modal/modal";
import { useSearch } from "@tanstack/react-router";
import { useCallback, useState } from "react";
import { PROVIDERS_MODAL_ID } from "../lib";

export function ProvidersModal() {
  const { isOpen, close } = useModal(PROVIDERS_MODAL_ID);

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={close} />
      <ProvidersComponent />
    </Modal>
  );
}

export function ProvidersComponent() {
  const { $t } = useTranslate();
  const { pid } = useSearch({ strict: false });
  const { close } = useModal(PROVIDERS_MODAL_ID);
  const [searchResult, setSearchResult] = useState<NonNullable<typeof sectionsListItems> | null>(
    null,
  );

  const { data } = Game.useProvidersList();

  const sectionsListItems = data?.result.data ?? [];

  const handleSearch = useCallback(
    debounce((value: string) => {
      if (!value) {
        setSearchResult(null);
      } else {
        setSearchResult(sectionsListItems.filter(({ name }) => name.toLowerCase().includes(value)));
      }
    }),
    [sectionsListItems],
  );

  const items = searchResult ?? sectionsListItems;

  return (
    <Modal.Panel className="top-0 max-h-full w-screen overflow-y-auto border-keppel bg-athens-gray p-3 lg:top-10 lg:max-h-[43rem] lg:w-[44.375rem] lg:rounded-rounded lg:border-1 lg:p-5 dark:border-java dark:bg-outer-space">
      <Modal.Title className="relative justify-start">
        <div>{$t({ defaultMessage: "Providers" })}</div>
        <Modal.CloseButton onClick={close} />
      </Modal.Title>
      <InputSearch onChange={handleSearch} />
      <div className="scrollbar-thin lg:-mr-4 mt-5 overflow-y-auto lg:pr-4">
        <div className="grid grid-cols-3 gap-3 lg:gap-5">
          {items.map(({ name, id, stringId }) => (
            <ProviderButton
              className="aspect-3/1 lg:aspect-5/1"
              key={id}
              name={name}
              id={id}
              stringId={stringId}
              isActive={pid === id}
              onClick={close}
            />
          ))}
        </div>
      </div>
    </Modal.Panel>
  );
}

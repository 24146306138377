import clsx from "clsx";
import { forwardRef, type ForwardedRef } from "react";

interface IInputProps {
  className?: string;
  label?: string;
  requiredMessage?: string;
}

export const Input = forwardRef(function Input(
  { className, label, requiredMessage, ...inputProps }: IInputProps & React.ComponentProps<"input">,
  ref?: ForwardedRef<HTMLInputElement>,
) {
  return (
    <label className={clsx(className, "flex flex-col lg:text-base text-xs gap-1")}>
      {label && <span className="font-bold">{label}:</span>}
      <div className="grow relative">
        <input
          className="peer bg-alto dark:bg-infinity rounded-rounded p-2.5 w-full h-9.5 placeholder:text-blue-bayoux font-normal focus:outline focus:outline-1 focus:outline-keppel"
          ref={ref}
          {...inputProps}
        />
        <div className="lg:text-xs text-2xs text-radical-red font-light absolute left-2.5 invisible peer-[:user-invalid]:visible">
          {requiredMessage}
        </div>
      </div>
    </label>
  );
});

import { useTranslate } from "@lobby/ocb-intl";

import { useJackpotsContext } from "@entities/jackpot";
import { useAuth } from "@shared/lib";
import { Skeleton } from "@shared/ui/skeleton";

import { JackpotTickers } from "./jackpot-tickers";

export function JackpotTickerSlots() {
  const { $t } = useTranslate();
  const { slots } = useJackpotsContext();
  const { isAuth } = useAuth();

  if (!isAuth) return null;

  return slots ? (
    <div className="lg:rounded-rounded lg:gap-x-10 lg:px-10 lg:gap-y-5 lg:py-5 md:flex-row bg-keppel bg-opacity-80 flex flex-wrap flex-col gap-x-5 gap-y-3 items-center justify-center p-3 dark:bg-java/20">
      <div className="lg:text-3xl font-commissioner font-extrabold italic text-sm text-white tracking-wider dark:text-cyan uppercase [text-shadow:0.125em_0.125em_0_rgba(0,0,0,0.25)]">
        {$t({ defaultMessage: "jackpot prizes" })}
      </div>
      <JackpotTickers />
    </div>
  ) : (
    <Skeleton className="bg-silver h-24 widescreen:h-20" />
  );
}

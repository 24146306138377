import { LobbySettingsContext } from "@lobby/core/entities/lobby";
import { getCategoryIconName, getCategoryImageSrc, isClientCategory } from "@shared/lib";
import { SVGIcon } from "@shared/ui";
import type { TIconName } from "@shared/ui";
import { clsx } from "clsx";
import { useContext } from "react";
import type { CSSProperties } from "react";

interface ICategoryIconProps {
  className?: string;
  id: string | number;
}

export function CategoryIcon({ className, id }: ICategoryIconProps) {
  const lobbyContext = useContext(LobbySettingsContext);

  return isClientCategory(id) ? (
    <SVGIcon
      className={clsx("size-[1em]", className)}
      name={getCategoryIconName(String(id)) as TIconName}
    />
  ) : (
    <div
      className={clsx("size-[1em]", className)}
      style={
        {
          backgroundColor: "currentcolor",
          mask: `url(${getCategoryImageSrc(lobbyContext.theme, id)}) center/100% no-repeat`,
        } as CSSProperties
      }
    />
  );
}

import { Link } from "@tanstack/react-router";

interface ISocialNetworkLinkProps {
  name: string;
  stringId: string;
  url: string;
}

export function SocialNetworkLink({ name, stringId, url }: ISocialNetworkLinkProps) {
  return (
    <Link
      to={url}
      key={stringId}
      className="relative flex-center rounded-rounded border-1 border-shark">
      <span className="invisible absolute max-w-full truncate has-[+.hidden]:visible">{name}</span>
      <img
        src={`/media/social-network/${stringId}.jpg`}
        alt={name}
        onError={(e) => (e.target as HTMLImageElement).classList.add("hidden")}
        className="size-full rounded-inherit object-cover"
      />
    </Link>
  );
}
